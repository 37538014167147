<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">#{{ data.id }} {{ data.name_de }}</h1>
    </div>
    <div class="card-content">
      <div class="columns view-columns is-multiline">
        <div class="column is-4">
          <label>ID</label>
          #{{ $utilities.padLeftWithNull(data.id) }}
        </div>
        <div class="column is-4">
          <label>Gültig von</label>
          {{ new Date(data.valid_from).toLocaleDateString('de-DE') }}
        </div>
        <div class="column is-4">
          <label>Gültig bis</label>
          {{ new Date(data.valid_to).toLocaleDateString('de-DE') }}
        </div>
        <div class="column is-4">
          <label>Bezeichnung/Aktion deutsch</label>
          {{ data.name_de }}
        </div>
        <div class="column is-4">
          <label>Bezeichnung/Aktion englisch</label>
          {{ data.name_en }}
        </div>
        <div class="column is-4">
          <label>Code</label>
          {{ data.code }}
        </div>

         <div class="column is-4">
          <label>Mindestbestellwert</label>
          {{ data.min_value }}
        </div>

        <div class="column is-4">
          <label>Rabatt in Prozent der Warensumme</label>
          {{ (data.discount_percent > 0) ? data.discount_percent+ ' %': ' '}}
        </div>

        <div class="column is-4">
          <label>Absoluter Rabatt (alternativ)</label>
          {{ (data.discount_absolute > 0) ? data.discount_absolute+ ' €': ' '}}
        </div>
        
        
       
      </div>

      <b-button
        class="button"
        type="is-info"
        @click="edit(data.id)"
        icon-left="square-edit-outline"
        >Bearbeiten</b-button
      >

      &nbsp;

      <b-button
        class="button"
        type="is-danger"
        icon-left="delete_forever"
        @click="delete1(data.id, data.name_de)"
        >Löschen</b-button
      >
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "countriesView",
  data() {
    return {
      vatruleOptions: [
        { id: 0, name: "Keine USt. berechnen" },
        { id: 1, name: "immer USt. berechnen" },
        { id: 2, name: "USt. berechnen, wenn keine VAT-ID" }
      ]
    }
  },
  props: ["data"],

  methods: {
    delete1: function(id, name) {
      this.$parent.close()
      this.$parent.$parent.delete1(id, name)
    },
    edit: function(id) {
      this.$parent.close()
      this.$parent.$parent.edit(id)
    }
  }
}
</script>
