<template>
  <div class="card">
    <form method="post" @submit.prevent="save">
      <div class="card-header">
        <h1 class="card-header-title">{{ typeOfAction }}</h1>
      </div>
      <div class="card-content">
        <div class="columns view-columns is-multiline">
          <div class="column is-4">
            <b-field label="ID"> #{{ $utilities.padLeftWithNull(data.id) }} </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Gültig ab"
              :type="{ 'is-danger': $v.data.valid_from2.$error }"
              :message="{
                '': submitted && $v.data.valid_from2.$error,
              }"
            >
              <b-datepicker
                placeholder="Type or select a date..."
                icon="calendar-today"
                locale="de-DE"
                v-model.trim="$v.data.valid_from2.$model"
                :class="{ is_danger: $v.data.valid_from2.$error }"
                :month-names="[
                  'Januar',
                  'Februar',
                  'März',
                  'April',
                  'Mai',
                  'Juni',
                  'Juli',
                  'August',
                  'September',
                  'Oktober',
                  'November',
                  'Dezember',
                ]"
                :day-names="['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']"
                :first-day-of-week="1"
                editable
              >
              </b-datepicker>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Gültig bis"
              :type="{ 'is-danger': $v.data.valid_to2.$error }"
              :message="{
                '': submitted && $v.data.valid_to2.$error,
              }"
            >
              <b-datepicker
                placeholder="Type or select a date..."
                icon="calendar-today"
                locale="de-DE"
                v-model.trim="$v.data.valid_to2.$model"
                :class="{ is_danger: $v.data.valid_to2.$error }"
                :month-names="[
                  'Januar',
                  'Februar',
                  'März',
                  'April',
                  'Mai',
                  'Juni',
                  'Juli',
                  'August',
                  'September',
                  'Oktober',
                  'November',
                  'Dezember',
                ]"
                :day-names="['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']"
                :first-day-of-week="1"
                editable
              >
              </b-datepicker>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Bezeichnung/Aktion deutsch"
              :type="{ 'is-danger': $v.data.name_de.$error }"
              :message="{
                'Bitte geben Sie einen deutschen Namen an.':
                  submitted && $v.data.name_de.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.name_de.$model"
                :class="{ is_danger: $v.data.name_de.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Bezeichnung/Aktion englisch"
              :type="{ 'is-danger': $v.data.name_en.$error }"
              :message="{
                'Bitte geben Sie einen englischen Namen an.':
                  submitted && $v.data.name_en.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.name_en.$model"
                :class="{ is_danger: $v.data.name_en.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Code"
              :type="{ 'is-danger': $v.data.code.$error }"
              :message="{
                'Bitte geben Sie einen mindestens 6stelligen Code an.':
                  submitted && $v.data.code.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.code.$model"
                :class="{ is_danger: $v.data.code.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Mindestbestellwert"
              :type="{ 'is-danger': $v.data.min_value.$error }"
              :message="{
                'Bitte geben Sie einen Mindestbestellwert an.':
                  submitted && $v.data.min_value.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.min_value.$model"
                :class="{ is_danger: $v.data.min_value.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Rabatt in Prozent der Warensumme"
              :type="{ 'is-danger': $v.data.discount_percent.$error }"
              :message="{
                'Bitte geben Sie den Rabatt an.':
                  submitted && $v.data.discount_percent.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.discount_percent.$model"
                :class="{ is_danger: $v.data.discount_percent.$error }"
              >
              </b-input>
            </b-field>
          </div>

          <div class="column is-4">
            <b-field
              label="Absoluter Rabatt (alternativ)"
              :type="{ 'is-danger': $v.data.discount_absolute.$error }"
              :message="{
                'Bitte geben Sie den Rabatt an.':
                  submitted && $v.data.discount_absolute.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.discount_absolute.$model"
                :class="{ is_danger: $v.data.discount_absolute.$error }"
              >
              </b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="column">
          <b-button class="button" type="button" @click="$parent.close()"
            >Schließen</b-button
          >
          &nbsp;

          <b-button
            class="button"
            type="is-info"
            native-type="submit"
            icon-left="cloud_upload"
            >Speichern</b-button
          >
        </div>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import { required, decimal, minLength, maxLength, numeric } from "vuelidate/lib/validators";
export default {
  name: "discountForm",
  props: ["pdata"],

  data() {
    return {
      data: this.pdata,
      error: null,
      isLoading: false,
      submitted: false,
    };
  },

  computed: {
    typeOfAction: function () {
      if (typeof this.data.id == "undefined" || this.data.id == null) {
        return "Neuen Gutscheincode anlegen";
      }
      return "Gutscheincode bearbeiten: #" + this.data.id + " " + this.data.name_de;
    },
  },
  validations: {
    data: {
      name_de: { required, minLength: minLength(3) },
      name_en: { required, minLength: minLength(3) },
      code: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(20),
      },
      valid_from2: { required },
      valid_to2: { required },
      min_value: { required, numeric },
      discount_absolute: { decimal },
      discount_percent: { decimal},
    },
  },

  methods: {
    save: function () {
      //console.log('customer-daten');
      //console.log(this.customer)
      this.error = null;
      this.submitted = true;
      this.$v.$touch();
      var t = this;
      if (t.$v.$invalid) {
        console.log("Fehler in Form");
      } else {
        t.$parent.$parent.save(t.data);
      }
    },
  },
};
</script>
<style></style>
