<template>
  <b-table
    :data="data.data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    :range-before="5"
    :range-after="5"
    paginated
    backend-pagination
    pagination-position="both"
    :total="data.count"
    :per-page="data.perpage"
    @page-change="onPageChange"
    :current-page.sync="data.page"
    aria-next-label="Weiter"
    aria-previous-label="Zurück"
    aria-page-label=" Seite "
    aria-current-label="Aktuelle Seite"
    backend-sorting
    :default-sort="[data.sortField, data.sortOrder]"
    @sort="onSort"
    icon-pack="icm"
    :mobile-cards="false"
  >
    <template slot="top-left">
      <div>
        <b-field grouped>
          <b-field class="control has-icons-right">
            <input
              class="input"
              placeholder="Suche"
              v-model="searchphrase"
              v-on:keyup.enter="list()"
            />
            <span class="icon is-right" @click="list()" id="searchbutton">
              <i class="icm icm-search"></i>
            </span>
          </b-field>
        </b-field>
      </div>
    </template>
    
    
    <b-table-column v-slot="props" field="id" label="Id" numeric sortable>
      <a
        :href="`/shop/livexview/${props.row.id}`"
        title="Ansehen"
        @click.prevent="livexView(props.row.id)"><b-icon icon="discout" class="green" v-if="props.row.specialoffer == 1"></b-icon>{{ $utilities.padLeftWithNull(props.row.id) }}</a>
    </b-table-column>

     <b-table-column v-slot="props" label="Bild" fiel="null">
      <img :src="getImagePath(props.row.wine_id)" width="18" height="18" alt="" />
    </b-table-column>   

    <b-table-column v-slot="props" field="producer_name_de" label="Wein" sortable>
      <a
        :href="`/shop/livexview/${props.row.id}`"
        title="Ansehen"
        @click.prevent="livexView(props.row.id)">{{ props.row.producer_name_de }}, {{ props.row.wine_name_de }}  {{ props.row.jahr == 0 ? "n.V." : props.row.jahr }}</a>
    </b-table-column>

    <b-table-column v-slot="props" field="producer_name_de" label="Einheit" numeric sortable>
      {{ props.row.flaschen_pro_einheit }} x {{ props.row.menge }}
    </b-table-column>

     <b-table-column v-slot="props" field="jahr" label="Flaschen" numeric sortable>
     {{ props.row.anzahl }}
    </b-table-column>

    <b-table-column v-slot="props" field="jahr" label="Livex-Preis" numeric sortable>
     {{ props.row.livex_price }}€
    </b-table-column>

     <b-table-column v-slot="props" field="jahr" label="VK-Preis" numeric sortable>
     {{ props.row.price }}€
    </b-table-column>

    

    <b-table-column
      v-slot="props"
      label="A"
      class="iconlink"
      field="link1"
      header-class="iconlink"
    >
      <a title="Ansehen" @click.prevent="view(props.row.id)">
        <b-icon icon="find_in_page"></b-icon>
      </a>
    </b-table-column>
  </b-table>
</template>

<script>
import LivexView from "./livexview.vue";
export default {
  name: "livex",
  data() {
    return {
      apiControllerName: "livex",
      loading: {},
      data: {
        count: 0,
        perpage: 50,
        data: [],
        page: 1,
        order: { field: "id", direction: "DESC" },
      },
      error: "",
      subtitle: "",
      isLoading: true,
      isReady: false,
      searchphrase: "",
      modal: false,
    };
  },

  mounted: function () {
    this.$store.commit("setHeadline", {
      subheadline: "Livex-Artikel im Shop",
    });

    this.list();
    this.isReady = true;
  },
  methods: {
    list: async function () {
      var t = this;
      t.isLoading = false; 
      t.$http
        .post("/" + t.apiControllerName, {
          page: t.data.page,
          search: t.searchphrase,
          order: t.data.order
        })
        .then(function(resp) {
          t.data = resp.data.data
          t.isLoading = false
          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
        })
        
    },
    onPageChange(page) {
      this.data.page = page;
      this.list();
    },
    /*
     * Handle sort event
     */
    onSort(field, direction) {
      //console.log(field + ' » ' + direction)

      this.data.order = { field, direction };
      this.list();
    },
    livexView: function (id) {
      var t = this;

      t.$http({
        url: "/" + t.apiControllerName + "/view/" + id,
        method: "get",
      })
        .then(function (resp) {
          //console.log( resp.data.data);

          t.modal = t.$buefy.modal.open({
            parent: t,
            component: LivexView,
            hasModalCard: false,
            width: 1250,
            props: { data: resp.data.data },
          });

          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
    },
    getImagePath (id) {
      var path = this.$store.getters.config.page.imageBaseUrl+"/images/";
      var diff = id % 100;
      if ((diff == 0)) {
        diff = "00";
      } else if (diff < 10) {
        diff = "0" + diff;
      }
      return path + diff + "/" + id + "_s.jpg";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#searchbutton {
  pointer-events: all !important;
  cursor: pointer !important;
}
</style>
