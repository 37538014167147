<template>
  <div class="card">
    <form method="post" @submit.prevent="save">
      <div class="card-header">
        <h1 class="card-header-title">{{ typeOfAction }}</h1>
      </div>
      <div class="card-content">
        <div class="columns view-columns">
          <div class="column">
            <b-field label="Versandregion-ID">
              #{{ $utilities.padLeftWithNull(data.id) }}
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Name Ländergruppe (deutsch)"
              :type="{ 'is-danger': $v.data.name_de.$error }"
              :message="{
                'Bitte geben Sie einen deutschen Namen an.':
                  submitted && $v.data.name_de.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.name_de.$model"
                :class="{ is_danger: $v.data.name_de.$error }"
              >
              </b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field
              label="Name Ländergruppe (englisch)"
              :type="{ 'is-danger': $v.data.name_en.$error }"
              :message="{
                'Bitte geben Sie einen englischen Namen an.':
                  submitted && $v.data.name_en.$error,
              }"
            >
              <b-input
                v-model.trim="$v.data.name_en.$model"
                :class="{ is_danger: $v.data.name_en.$error }"
              >
              </b-input>
            </b-field>
          </div>
        </div>

        <shipping-rule
          class="panel-block"
          :id="'rule' + r.key"
          v-for="(r, index) in data.rules"
          :key="index"
          :r="r"
          :inputmode="true"
          :selectWeightOptions="selectWeightOptions"
          @rule-delete="ruleDelete"
        />

        <div>
          <button @click.prevent.stop="addShippingRule()" class="button">
            Gewichtsregel hinzufügen
          </button>
        </div>
      </div>
      <div class="card-footer">
        <div class="column">
          <b-button class="button" type="button" @click="$parent.close()"
            >Schließen</b-button
          >
          &nbsp;

          <b-button
            class="button"
            type="is-info"
            native-type="submit"
            icon-left="cloud_upload"
            >Speichern</b-button
          >
        </div>
      </div>
    </form>
    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";
import ShippingRule from "../_helpers/shippingrule.vue";
export default {
  name: "shippingForm",
  props: ["pdata"],
  components: {
    ShippingRule,
  },

  data() {
    return {
      error: null,
      data: {},
      noOfRules: 0,
      isLoading: false,
      selectWeightOptions: this.selectWeight(),
      submitted: false,
      ruleList: [],
      isFetching: false,
    };
  },

  computed: {
    typeOfAction: function () {
      if (typeof this.data.id == "undefined" || this.data.id == null) {
        return "Neuen Versandländergruppe anlegen";
      }
      return "Versandländergruppe bearbeiten: #" + this.data.id;
    },
  },

  validations: {
    data: {
      name_de: {
        required,
        minLength: minLength(1),
      },
      name_en: {
        required,
        minLength: minLength(1),
      },
    },
  },

  mounted: function () {
    this.data = this.pdata;
    this.noOfRules = this.data.rules.length;
  },

  methods: {
    save: function () {
      this.error = null;
      this.submitted = true;
      this.$v.$touch();
      var t = this;
      if (t.$v.$invalid) {
        //console.log('Fehler in Form')
      } else {
        t.$parent.$parent.save(t.data);
      }
    },

    addShippingRule: function () {
      this.noOfRules++;
      this.data.rules.push({
        id: this.noOfRules,
        weight: 1,
        price: 5,
      });
    },

    selectWeight: function () {
      var sel = [];
      for (var i = 1; i <= 120; i++) {
        sel.push({ id: i, name: i + " kg" });
      }
      return sel;
    },

    ruleDelete(option) {
      for (var i = 0; i < this.data.rules.length; i++) {
        if (this.data.rules[i].id == option.id) {
          this.data.rules.splice(i, 1);
          break;
        }
      }
    },
  },
};
</script>
<style></style>
