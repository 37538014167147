<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">
        #{{ data.id }} {{ data.article.wine.producer.producer_name_de }}, {{ data.article.wine.name_de }}
        {{ data.article.wine.jahr == 0 ? "n.V." : data.article.wine.jahr }}
      </h1>
    </div>
    <div class="card-content">
      <div class="columns view-columns">
        <div class="column">
          <label>Id</label>
          #{{ data.id }}
        </div>

        <div class="column">
          <label>Anbauregion</label>
          {{ data.article.wine.region.name_de }}
        </div>
        <div class="column">
          <label>Anzahl Flaschen</label>
          {{ data.anzahl }}
        </div>
        <div class="column">
          <label>Flaschen pro Einheit</label>
          {{ data.article.flaschen_pro_einheit }}
        </div>
        <div class="column">
          <label>Flaschengröße</label>
          {{ data.article.menge }}L
        </div>
      </div>
      <div class="columns view-columns">
        <div class="column">
          <label>Livex-Id</label>
          {{ data.order_guid }}
        </div>
        <div class="column">
          <label>Livex-Preis/Fl.</label>
          {{ data.livex_price }}€
        </div>
        <div class="column">
          <label>Shop-Preis/Fl.</label>
          {{ data.price }}€
        </div>
        <div class="column">
          <label>Lwin18</label>
          {{ data.lwin18 }}€
        </div>
        <div class="column">
          <label>Aktualisiert</label>
          {{ $utilities.formatUnixtimeToDmYHi(data.ts) }}
        </div>
      </div>

      <div class="columns view-columns">
        <div class="column">
          <b-switch v-model="specialoffer" true-value="1" false-value="0" size="is-small" @input="updateSpecialofferStatus">
          </b-switch>
          <b-icon icon="discout" :class="{ green: specialoffer == 1, grey: specialoffer == 0 }" style="float: none"></b-icon>
          Als Sonderangebot auf der Startseite bewerben
        </div>
      </div>
       <div class="columns view-columns">
       <div class="column">
          <label>SG-Artikel-Id</label>
          {{ data.article.id }}
        </div>
        <div class="column">
          <label>SG-Wein-Id</label>
          {{ data.article.wine_id }}
        </div>
       </div>
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">Schließen</button>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "shippingRegionView",
  data() {
    return {
      specialoffer: 0,
    };
  },
  props: ["data"],
  mounted: function () {
    
    this.specialoffer = this.data.specialoffer;
  },

  methods: {
    updateSpecialofferStatus() {
      //console.log('savePrice', this.w.price_vk);
      var t = this;
      t.$http
        .post("/livex/update-specialoffer-status", {
          id: t.data.id,
          specialoffer: t.specialoffer,
        })
        .then(function (resp) {
          t.$parent.$parent.list();
          return true;
        })
        .catch(function (error) {
          console.log(error);
          alert("Es ist ein unbekannter Fehler aufgetreten");

          return false;
        });
    },
    getImagePath(id) {
      var path = this.$store.getters.config.page.imageBaseUrl+"/images/";
      var diff = id % 100;
      if ((diff == 0)) {
        diff = "00";
      } else if (diff < 10) {
        diff = "0" + diff;
      }
      return path + diff + "/" + id + "_s.jpg";
    },
    
  },
};
</script>
