<template>
  <div class="columns view-columns ">
    <div v-if="inputmode2" class="field-body">
      <b-field label="Gewicht bis">
        <b-select v-model.number="r.weight" label="" size="is-small">
          <option
            v-for="option in selectWeightOptions"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select></b-field
      >

       <b-field label="Kosten netto in Euro">
        <b-input v-model="r.price" placeholder="Score" class="is-2" size="is-small"></b-input>
      </b-field>
      <a title="Löschen" @click.prevent="delete1(r)">
        <b-icon icon="delete_forever"></b-icon>
      </a>
    </div>

  </div>
</template>

<script>
export default {
  name: "Shippingrule",

  data() {
    return {
      inputmode2: false,
    };
  },

  props: {
    r: { required: true },
    selectWeightOptions: { required: true },
    inputmode: { type: Boolean, required: true },
  },
  created: function () {
    this.inputmode2 = this.inputmode;
  },

  methods: {
    delete1: function (r) {
      this.$emit('rule-delete', r);
    },
    
  },
};
</script>

<style>
div.wine-data {
  padding-bottom: 8px;
  margin-bottom: 8px;
}

div.deletelink span.icon {
  color: rgb(230, 20, 20);
}

div.deletelink button.button {
  border-color: rgb(230, 20, 20);
}
</style>
