import { render, staticRenderFns } from "./orders.vue?vue&type=template&id=0b00a5ad&scoped=true"
import script from "./orders.vue?vue&type=script&lang=js"
export * from "./orders.vue?vue&type=script&lang=js"
import style0 from "./orders.vue?vue&type=style&index=0&id=0b00a5ad&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../.yarn/__virtual__/vue-loader-virtual-204c11ec9d/0/cache/vue-loader-npm-15.11.1-829412283a-509a816d45.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b00a5ad",
  null
  
)

export default component.exports