var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"card-header"},[_c('h1',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.typeOfAction))])]),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"columns view-columns is-multiline"},[_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"ID"}},[_vm._v(" #"+_vm._s(_vm.$utilities.padLeftWithNull(_vm.data.id))+" ")])],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Gültig ab","type":{ 'is-danger': _vm.$v.data.valid_from2.$error },"message":{
              '': _vm.submitted && _vm.$v.data.valid_from2.$error,
            }}},[_c('b-datepicker',{class:{ is_danger: _vm.$v.data.valid_from2.$error },attrs:{"placeholder":"Type or select a date...","icon":"calendar-today","locale":"de-DE","month-names":[
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
              ],"day-names":['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],"first-day-of-week":1,"editable":""},model:{value:(_vm.$v.data.valid_from2.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.valid_from2, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.valid_from2.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Gültig bis","type":{ 'is-danger': _vm.$v.data.valid_to2.$error },"message":{
              '': _vm.submitted && _vm.$v.data.valid_to2.$error,
            }}},[_c('b-datepicker',{class:{ is_danger: _vm.$v.data.valid_to2.$error },attrs:{"placeholder":"Type or select a date...","icon":"calendar-today","locale":"de-DE","month-names":[
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
              ],"day-names":['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],"first-day-of-week":1,"editable":""},model:{value:(_vm.$v.data.valid_to2.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.valid_to2, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.valid_to2.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Bezeichnung/Aktion deutsch","type":{ 'is-danger': _vm.$v.data.name_de.$error },"message":{
              'Bitte geben Sie einen deutschen Namen an.':
                _vm.submitted && _vm.$v.data.name_de.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.name_de.$error },model:{value:(_vm.$v.data.name_de.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.name_de, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.name_de.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Bezeichnung/Aktion englisch","type":{ 'is-danger': _vm.$v.data.name_en.$error },"message":{
              'Bitte geben Sie einen englischen Namen an.':
                _vm.submitted && _vm.$v.data.name_en.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.name_en.$error },model:{value:(_vm.$v.data.name_en.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.name_en, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.name_en.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Code","type":{ 'is-danger': _vm.$v.data.code.$error },"message":{
              'Bitte geben Sie einen mindestens 6stelligen Code an.':
                _vm.submitted && _vm.$v.data.code.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.code.$error },model:{value:(_vm.$v.data.code.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.code, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.code.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Mindestbestellwert","type":{ 'is-danger': _vm.$v.data.min_value.$error },"message":{
              'Bitte geben Sie einen Mindestbestellwert an.':
                _vm.submitted && _vm.$v.data.min_value.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.min_value.$error },model:{value:(_vm.$v.data.min_value.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.min_value, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.min_value.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Rabatt in Prozent der Warensumme","type":{ 'is-danger': _vm.$v.data.discount_percent.$error },"message":{
              'Bitte geben Sie den Rabatt an.':
                _vm.submitted && _vm.$v.data.discount_percent.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.discount_percent.$error },model:{value:(_vm.$v.data.discount_percent.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.discount_percent, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.discount_percent.$model"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Absoluter Rabatt (alternativ)","type":{ 'is-danger': _vm.$v.data.discount_absolute.$error },"message":{
              'Bitte geben Sie den Rabatt an.':
                _vm.submitted && _vm.$v.data.discount_absolute.$error,
            }}},[_c('b-input',{class:{ is_danger: _vm.$v.data.discount_absolute.$error },model:{value:(_vm.$v.data.discount_absolute.$model),callback:function ($$v) {_vm.$set(_vm.$v.data.discount_absolute, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.data.discount_absolute.$model"}})],1)],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"column"},[_c('b-button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_vm._v("Schließen")]),_vm._v("   "),_c('b-button',{staticClass:"button",attrs:{"type":"is-info","native-type":"submit","icon-left":"cloud_upload"}},[_vm._v("Speichern")])],1)])]),_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading,"can-cancel":false},on:{"update:active":function($event){_vm.isLoading=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }